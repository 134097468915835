// extracted by mini-css-extract-plugin
export var current = "layout-module--current--7f9fe";
export var hidden = "layout-module--hidden--b4d9e";
export var left = "layout-module--left--ada48";
export var light = "layout-module--light--62f45";
export var main = "layout-module--main--e5529";
export var mediumBreakpoint = "960px";
export var mobileBreakpoint = "480px";
export var nav = "layout-module--nav--7fd4b";
export var navIcon = "layout-module--nav-icon--01d02";
export var navItem = "layout-module--nav-item--3670c";
export var navOpen = "layout-module--nav-open--c8a0a";
export var navTransition = "0.4s";
export var navWidth = "320px";
export var right = "layout-module--right--3203b";
export var title = "layout-module--title--2b201";
export var toggle = "layout-module--toggle--e4b24";